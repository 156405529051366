

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
// import debounce from 'lodash.debounce';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { Debounce } from 'vue-debounce-decorator';
// import debounce from 'lodash.debounce';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
// import isOnline from 'is-online';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import { TASQS_LISTS, SHOW_ALERT, SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import scheduleModule from '@/store/modules/scheduleModule';
import metaDataModule from '@/store/modules/metaDataModule';
import userPreferenceDB from '@/lib/userPreference';
import { sort } from 'fast-sort';
// import DataLoading from '@/lib/mixins/dataLoading';

@Component({
  components: {
    TasqItem: () => getComponent('tasqs/TasqItem'),
    PadItem: () => getComponent('tasqs/PadItem'),
    WellViewItem: () => getComponent('tasqs/WellItem'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    Dropdown: () => getComponent('common/Dropdown'),
    SwipeOut,
    Multiselect,
    SwipeList,
  },
})
export default class TasqsVerticalList extends mixins() {
  @Prop({ type: Boolean, default: false }) isMobileView!: boolean;

  filterOpen = false;

  dataLoading = false

  isLoadingDetails = false;

  sortType = 'deferment-desc';

  selectedSortType = 'deferment-desc';

  totalTasqCount = 0

  dropdownJobTypeLocation = []

  dropdownUsersLocation = []

  dropdownAreasLocation = []

  dropdownRoutesLocation = []

  dropdownWaitingOnLocation = []

 dropdownTeamsLocation = []

 waitingOnOptions: any = []

  isOnlineApp = navigator.onLine;

  completedVisible = false;

  showAddTasqTooltip = false;

  showEditTasqTooltip = false;

  showFilterTasqTooltip = false;

  showSortTasqTooltip = false;

  sortPopupOpen = false;

  selectedUsernames: any = []

  selectedRoutes: any = [];

  selectedWaitingOn: any = [];

  removeJobType(option) {
    const index = this.typesOptions.indexOf(option);
    if (index !== -1) {
      this.typesOptions.splice(index, 1);
    }
  }

  selectedTeams: any = []

  selectedAreas: any = []

  maxNumCycleMax = 7;

   maxNumDefermentMax = 7;

  cycleTimeGreaterThanFilter: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  defermentPercentGreaterThanFilter: any = null;

   defermentPercentLessThanFilter: any = null;

  filterVal = '';

  filterValInput = '';

  activeList = 'ALL';

  showSearch = false;

  readyForScrolling = false;

  isFistLoading = false;

  registerScrollEvent = false;

  registerChangeEvent = false;

  showInfiniteScrollLoading = false;

  cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];

  defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];

  typesOptions = [] as any;

  toggleFilteringPopup() {
    this.sortPopupOpen = false;
    this.$nextTick(() => {
      this.filterOpen = !this.filterOpen;
    });
  }

  toggleSortPopup() {
    this.filterOpen = false;
    this.$nextTick(() => {
      this.sortPopupOpen = !this.sortPopupOpen;
    });
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  applySort() {
    this.sortPopupOpen = false;

    setTimeout(() => {
      this.currentTasqLimit = 0;
      this.selectedSortType = this.sortType;
      this.currentTasqLimit = 20;
    }, 200);
  }

  cancelSort() {
    this.sortType = this.selectedSortType;
    this.sortPopupOpen = false;
  }

  hideDropdown() {
		  // console.log(this.selectedSignals);
    // this.stx.initialData = this.stx.chart.series[00]
    this.filterOpen = true;
    this.dropdownJobTypeLocation = [];
    this.dropdownUsersLocation = [];
    this.dropdownAreasLocation = [];

    this.dropdownRoutesLocation = [];

    this.dropdownTeamsLocation = [];

    this.dropdownWaitingOnLocation = [];
  }

  async didSelectDropdownOption(e) {
		  // console.log(this.stx.drawingObjects);
    this.filterOpen = true;

		  // ts-ignore
  }

  closeSeriesDialog() {
		  this.dropdownJobTypeLocation = [];
    this.dropdownUsersLocation = [];
    this.dropdownJobTypeLocation = [];
    this.dropdownUsersLocation = [];
    this.dropdownAreasLocation = [];

    this.dropdownRoutesLocation = [];

    this.dropdownTeamsLocation = [];
    this.dropdownWaitingOnLocation = [];
    this.filterOpen = true;
  }

  didSelectJobTypeDropdown(e) {
    this.dropdownUsersLocation = [];
    this.dropdownUsersLocation = [];
    this.dropdownAreasLocation = [];

    this.dropdownRoutesLocation = [];

    this.dropdownTeamsLocation = [];
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
    // @ts-ignore
		  this.dropdownJobTypeLocation = [rect.x - 10, rect.y - 500];
  }

  didSelectUsersDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
    // @ts-ignore
		  this.dropdownUsersLocation = [rect.x - 10, rect.y - 300];
  }

  didSelectAreasDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
    // @ts-ignore
		  this.dropdownAreasLocation = [rect.x - 10, rect.y - 100];
  }

  didSelectTeamsDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
    // @ts-ignore
		  this.dropdownTeamsLocation = [rect.x - 10, rect.y - 100];
  }

  didSelectWaitingOnDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
    // @ts-ignore
		  this.dropdownWaitingOnLocation = [rect.x - 10, rect.y - 100];
  }

  didSelectRoutesDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
    // @ts-ignore
		  this.dropdownRoutesLocation = [rect.x - 10, rect.y - 100];
  }

  setCycleTimeMaxValue(search) {
    if (search) {
      this.maxNumCycleMax = 1;
      this.cycleTimeValues = [search];
    } else {
      this.maxNumCycleMax = 7;
      this.cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];
    }
  }

  setDefermentMaxValue(search) {
    if (search) {
      this.maxNumDefermentMax = 1;
      this.defermentUnitValues = [search];
    } else {
      this.maxNumDefermentMax = 7;
      this.defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];
    }
  }

  get isLoadingTasqs() {
    return tasqsListModule.isLoadingTasqs;
  }

  get isLoadingPaginatedTasqs() {
    return tasqsListModule.isLoadingPaginatedTasqs;
  }

  get isLoadingSearchTasqs() {
    return tasqsListModule.isLoadingSearchTasqs;
  }

  get availableUsers() {
    return accountModule.reassignmentList.filter((u) => u && u.email).map((i) => ({
      // @ts-ignore
      text: i.name || 'Test User',
      value: i.email || '',
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get tasqListLevel() {
	  return tasqsListModule.tasqListLevel;
  }

  get listOptions() {
    return TASQS_LISTS;
  }

  itemClick(e) {
    tasqsListModule.setShowTasqDetailsMobile(true);
  }

  get moreTotalTasqs() {
    return tasqsListModule.todaysTasqLength;
  }

  get morePaginatedData() {
    return (tasqsListModule.currentTasqListPageNo <= tasqsListModule.tasqTotalPages) || tasqsListModule.tasqStartLimit === 0;
  }

  get tasqs(): TasqJob[] {
    const { tasqList } = tasqsListModule;
    if ((this.tasqListLevel === 'WellView')) {
      return [...tasqList, ...this.producingWells];
    } if (this.tasqListLevel === 'Well' && (this.filterVal || this.selectedFilterRoute.length || this.selectedFilterArea.length)) {
      const tasqs = tasqsListModule.allTasqs;
      let newProducingList: any = []
      this.producingWells.forEach((well) => {
        if (!tasqs.find((tasq) => tasq.wellName === well.wellName)) {
          newProducingList.push(well);
        }
      });
      return [...tasqList, ...newProducingList];
    }
    return tasqList;
  }

  currentTasqLimit = 20;

   currentCompletedLimit = 20;

   get producingWells(): TasqJob[] {
     return tasqsListModule.producingWells;
   }

  @Debounce(1000)
  @Watch('producingWells')
   onProducingWellsUpdate(data) {
     if (data && data.length) {
       console.log('wells updated');
       this.applyFiltering(true);
     }
   }

   @Watch('completedVisible')
  onCompletedWellsUpdate() {
    this.currentTasqLimit = 20;
  }

   get enabledWells() {
     return assetsModule.enabledWells;
   }

   get enableRoutes() {
	  const routes = scheduleModule.enabledRoutes.map((r) => r.Route);
	  routes.sort((a, b) => {
       if (a < b) { return -1; }
       if (a > b) { return 1; }
       return 0;
     });
     return routes;
   }

   get paginationHeight() {
     return tasqsListModule.infiniteScrollHeight;
   }

   get filteredCompletedTasqs(): TasqJob[] {
     let tasqs = this.completedTasqs;
     if (this.filterVal) {
       tasqs = this.completedTasqs.filter(
         (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
       );
     }
     return tasqs.filter((t) => this.typesOptions.includes(t.overriddenName));
   }

   get filteredProducingWells(): any[] {
	  let totalProducingWells: any[] = [];
	  for (let x = 0; x < this.enabledWells.length; x++) {
       let addToProducingWells = true;
       for (let y = 0; y < this.tasqs.length; y++) {
         if (this.tasqs[y].wellName == this.enabledWells[x].wellName) {
           addToProducingWells = false;
         }
       }
       if (addToProducingWells) {
         totalProducingWells.push(this.enabledWells[x]);
       }
	  }
     totalProducingWells = totalProducingWells.filter(
       (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase()),
     );
	  return totalProducingWells;
   }

   applySortOnTasqs(tasqs) {
    const results = tasqs;
    let sortKey: any = 'defermentValue';
    if (this.selectedSortType === 'deferment-desc') {
      sortKey = 'defermentValue';
      if (this.tasqListLevel === 'Pad') {
        sortKey = 'padDeferment';
      }
      return sort(results).desc(sortKey);
    }

    if (this.selectedSortType === 'deferment-asc') {
      sortKey = 'defermentValue';
      if (this.tasqListLevel === 'Pad') {
        sortKey = 'padDeferment';
        return sort(results).asc(sortKey);
      }
      // @ts-ignore
    }

    if (this.selectedSortType === 'cycleTime-asc') {
      sortKey = 'deferringDays';
      return sort(results).asc(sortKey);
    }

    if (this.selectedSortType === 'cycleTime-desc') {
      sortKey = 'deferringDays';
      return sort(results).desc(sortKey);
    }

    if (this.selectedSortType === 'defermentPercent-asc') {
      sortKey = 'defermentPercentage';
      return sort(results).asc(sortKey);
    }

    if (this.selectedSortType === 'defermentPercent-desc') {
      sortKey = 'defermentPercentage';
      return sort(results).desc(sortKey);
    }

    if (this.selectedSortType === 'wellName-asc') {
      if (this.tasqListLevel === 'Well' || this.tasqListLevel === 'WellView') {
        sortKey = 'wellName';
        // @ts-ignore
        return sort(results).asc(sortKey);
      }
      sortKey = 'padName';
      return sort(results).asc(sortKey);
    }

    if (this.selectedSortType === 'wellName-desc') {
      if (this.tasqListLevel === 'Well' || this.tasqListLevel === 'WellView') {
        // @ts-ignore
        sortKey = 'wellName';
        return sort(results).desc(sortKey);
      }
      // @ts-ignore
      return sort(results).desc(sortKey);
    }

    if (this.selectedSortType === 'actioned-desc') {
      sortKey = 'lastActionTime';
      // @ts-ignore
      return sort(results).desc(sortKey);
    }

    if (this.selectedSortType === 'actioned-asc') {
      sortKey = 'lastActionTime';
      // @ts-ignore
      return sort(results).asc(sortKey);
    }

    return results;
  }

   applyFilteringOnTasqs(tasqs) {
     let results = tasqs;

     if (this.tasqListLevel === 'Pad') {
       if (this.selectedFilterWaitingOn && this.selectedFilterWaitingOn.length) {
         // results = results.filter((t) => this.selectedFilterWaitingOn.includes(t.waitUntilReason));
       }
     } else if (this.selectedFilterWaitingOn && this.selectedFilterWaitingOn.length) {
       // console.log(.map(r => r));
       results = results.filter((t) => this.selectedFilterWaitingOn.find((status) => {
         if (status === 'None') {
           return t.waitUntilReason === '';
         }
         return t.waitUntilReason && t.waitUntilReason.split('-Remarks:')[0] && status.toLowerCase() === (t.waitUntilReason.split('-Remarks:')[0].trim().toLowerCase());
       }));
     }

     if (this.selectedFilterJobType && this.selectedFilterJobType.length) {
       results = results.filter((t) => this.selectedFilterJobType.includes(t.predictionType) || this.selectedFilterJobType.includes(`${t.predictionType} ${t.highLP}`));
     }

     if (this.selectedFilterTeam && this.selectedFilterTeam.length) {
       if (this.tasqListLevel === 'Pad') {
         // @ts-ignore
         results = results.filter((t) => !!t.wells.find((well) => (this.selectedFilterTeam.includes(well.team))));
       } else {
         // @ts-ignore
         results = results.filter((t) => this.selectedFilterTeam.includes(t.team));
       }
     }

     if (this.selectedFilterUsernames && this.selectedFilterUsernames.length) {
       results = results.filter((t) => this.selectedFilterUsernames.includes(t.username));
     }

     if (this.selectedFilterArea && this.selectedFilterArea.length) {
       // @ts-ignore
       if (this.tasqListLevel === 'Pad') {
         // @ts-ignore
         results = results.filter((t) => !!t.wells.find((well) => (this.selectedFilterArea.includes(well.area))));
       } else {
         // @ts-ignore
         results = results.filter((t) => t.area && this.selectedFilterArea.includes(t.area));
       }
     }

     if (this.selectedFilterRoute && this.selectedFilterRoute.length) {
       // @ts-ignore
       if (this.tasqListLevel === 'Pad') {
         // @ts-ignore
         results = results.filter((t) => !!t.wells.find((well) => (this.selectedFilterRoute.includes(well.route))));
       } else {
         // @ts-ignore
         results = results.filter((t) => t.area && this.selectedFilterRoute.includes(t.route));
       }
     }

     if (this.selectedFilterDefermentGreaterThan) {
       results = results.filter((t) => t.defermentValue && t.defermentValue > this.selectedFilterDefermentGreaterThan);
     }

     if (this.selectedFilterDefermentLessThan) {
       results = results.filter((t) => t.defermentValue && t.defermentValue < this.selectedFilterDefermentLessThan);
     }

     if (this.selectedFilterDefermentPercentGreaterThan) {
       results = results.filter((t) => t.defermentPercentage && t.defermentPercentage > this.selectedFilterDefermentPercentGreaterThan);
     }

     if (this.selectedFilterDefermentPercentLessThan) {
       results = results.filter((t) => t.defermentPercentage && t.defermentPercentage < this.selectedFilterDefermentPercentLessThan);
     }

     if (this.selectedFilterCycleTimeGreaterThan) {
       results = results.filter((t) => t.deferringDays && t.deferringDays > this.selectedFilterCycleTimeGreaterThan);
     }

     if (this.selectedFilterCycleTimeLessThan) {
       results = results.filter((t) => t.deferringDays && t.deferringDays < this.selectedFilterCycleTimeLessThan);
     }

     return results;
   }

   get wellTasqs(): TasqJob[] {
     return tasqsListModule.additionalPadNestedTasqs;
   }

   get filteredTasqs() {
     let results = this.filteredTasqResults;

     if (results) {
       if (this.filterVal && this.filterVal.length) {
         if (this.tasqListLevel === 'Well' || this.tasqListLevel === 'WellView') {
           const waitingOnTasqs: any = results.filter((t) => t.snoozed);
           results = results.filter((t) => !t.snoozed);
           // console.log(waitingOnTasqs);

           // @ts-ignore
           results = results.concat(waitingOnTasqs);
           // results = results.concat(this.producingWells);
           results = results.filter(
             (i) => i.wellName && (i.wellName).toLowerCase().includes(this.filterVal.toLowerCase()),

           );
         } else {
           results = results.filter(
             (i) => i.padName && (i.padName).toLowerCase().includes(this.filterVal.toLowerCase()),
           );
         }
       }

       if (this.tasqListLevel === 'Pad') {
         let count = 0;
         results.forEach((t) => {
           count += this.wellTasqs.filter((tasq) => tasq.predictionType !== 'Producing' && tasq.padName === t.padName).length;
         });

         this.totalTasqCount = count;
       } else {
         this.totalTasqCount = results.filter((tasq) => tasq.predictionType !== 'Producing').length;
       }

       if (this.tasqListLevel === 'WellView' && results) {
        results = this.applySortOnTasqs(results);
         const newResults: any = [];
         results.forEach((tasq) => {
           const index = newResults.findIndex((t) => t.wellName === tasq.wellName);
           if (index > -1 && tasq.defermentValue) {
             //  newResults[index].defermentValue += tasq.defermentValue;
           } else if (index < 0) {
             let anotherTasq: any = tasq;
             const relatedTasq = this.tasqs.find((t) => t.wellName === tasq.wellName && t.predictionType === 'Off-Target');
             if (relatedTasq) {
              //  console.log(relatedTasq);
               anotherTasq = relatedTasq;
             }
             newResults.push(anotherTasq);
           }
         });
         results = newResults;
       }

       results = this.applySortOnTasqs(results);

       return results.filter((tasq, index) => index < this.currentTasqLimit);
     }
     return [];
   }

  filteredTasqResults: any = [];

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }

  get progressPercentage() {
    return tasqsListModule.progressPercentage;
  }

  get selectedFilterJobType() {
    return tasqsListModule.jobTypeFilter;
  }

  get selectedFilterCycleTimeGreaterThan() {
    return tasqsListModule.cycleTimeGreaterThanFilter;
  }

  get selectedFilterCycleTimeLessThan() {
    return tasqsListModule.cycleTimeLessThanFilter;
  }

  get selectedFilterDefermentGreaterThan() {
    return tasqsListModule.unitsGreaterThanFilter;
  }

  get selectedFilterDefermentLessThan() {
    return tasqsListModule.unitsLessThanFilter;
  }

  get selectedFilterDefermentPercentGreaterThan() {
    return tasqsListModule.defermentPercentGreaterThanFilter;
  }

  get selectedFilterDefermentPercentLessThan() {
    return tasqsListModule.defermentPercentLessThanFilter;
  }

  get selectedFilterUsernames() {
    return tasqsListModule.usernamesList;
  }

  get selectedFilterArea() {
    return tasqsListModule.activeFilterArea;
  }

  get selectedFilterTeam() {
    return tasqsListModule.activeFilterTeam;
  }

  get selectedFilterRoute() {
    return tasqsListModule.activeFilterRoute;
  }

  get selectedFilterWaitingOn() {
    return tasqsListModule.activeFilterWaitingOn;
  }

  addTasq() {
	  this.$emit('add-tasq');
  }

  editTasqs() {
	  this.$emit('edit-tasq');
  }

  async reassignTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('reassign-tasq');
  }

  async snoozeTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('snooze-tasq');
  }

  async waitingOnTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('waiting-on-tasq');
  }

  async rejectTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('reject-tasq');
  }

  nameWithDays(name) {
    return `${name} ${name === '1' ? 'Day' : 'Days'}`;
  }

  nameWithUnits(name) {
    return `${name}`;
  }

  async setTasqListLevel(level) {
    this.filterOpen = false;
    tasqsListModule.resetPagination();
    tasqsListModule.setTasqListLevel(level);
    if (level === 'WellView') {
      this.currentTasqLimit = 10;
    }
    await this.changeTasqList();
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  closeFilterDialog() {
    if (!this.isMobileView) {
      this.filterOpen = false;
    }
  }

  closeSortDialog() {
    this.sortPopupOpen = false;
  }

  showSearchSelected() {
    this.showSearch = true;

    this.sleep(500).then(() => {
	  // @ts-ignore
	  this.$refs.searchTextFieldRef.focus();
    });
  }

  get areas() {
    return scheduleModule.areas;
  }

  get teams() {
    return workflowModule.teams;
  }

  async changeTasqList() {
    this.filterOpen = false;
    const list = this.listOptions.find((i) => i.val === this.activeList) as {text: string, val: string};

    tasqsListModule.setActiveTasq('');
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    tasqsListModule.resetChekedTasqs();
    // tasqsListModule.setActiveFilterList(list);
    // tasqsListModule.setSubstringFilter(this.filterVal);
    // tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
    // tasqsListModule.setActiveAreaFilter(this.selectedAreas);
    // tasqsListModule.setPredictionTypeFilter(this.typesOptions);

    await tasqsListModule.getTasqs(list);
  }

  async resetFiltering() {

    this.filterOpen = false;
    await this.sleep(10)
    this.selectUsername([]);
    this.selectedUsernames = [];
    this.typesOptions = [];
    this.selectedRoutes = [];
    this.selectedTeams = [];
    this.cycleTimeGreaterThanFilter = null;
    this.selectedAreas = [];
    this.cycleTimeLessThanFilter = null;
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
    this.defermentPercentGreaterThanFilter = null;
    this.defermentPercentLessThanFilter = null;
    this.selectedWaitingOn = [];
    await this.applyFiltering();
  }

  async applyFiltering(notResetLimit = false, useLocalFilter = false) {
    if (useLocalFilter) {
      tasqsListModule.setLocalFilter(true);
    }
    if (!notResetLimit) {
      this.currentTasqLimit = 10;
      this.filterOpen = false;
      await this.sleep(10);

      this.selectedUsernames = this.selectedUsernames.filter((u) => u && u.value);
      this.selectUsername(this.selectedUsernames.map((u) => u.value));

      tasqsListModule.setJobTypeFilter(this.typesOptions);
      tasqsListModule.setActiveTeamFilter(this.selectedTeams);
      tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
      tasqsListModule.setActiveAreaFilter(this.selectedAreas);
      tasqsListModule.setActiveWaitingOnFilter(this.selectedWaitingOn);
      tasqsListModule.setCycleTimeLessThanFilter(this.cycleTimeLessThanFilter);
      tasqsListModule.setUnitsLessThanFilter(this.unitsLessThanFilter);
      tasqsListModule.setUnitsGreaterThanFilter(this.unitsGreaterThanFilter);
      tasqsListModule.setCycleTimeGreaterThanFilter(this.cycleTimeGreaterThanFilter);
      tasqsListModule.setDefermentPercentLessThanFilter(this.defermentPercentLessThanFilter);
      tasqsListModule.setDefermentPercentGreaterThanFilter(this.defermentPercentGreaterThanFilter);

      
      this.currentTasqLimit = 20;
      const infiniteList = document.getElementById('infinite-list')!;
      if (infiniteList) {
        infiniteList.scrollTo({ top: 0 });
      }
    }

    let allTasqs = this.tasqs;

    if (this.filterVal && this.filterVal.length) {
      allTasqs = [...allTasqs, ...this.completedTasqs];
    }
    this.filteredTasqResults = this.applyFilteringOnTasqs(allTasqs);
  }

  selectUsername(users) {
    tasqsListModule.setUsernamesBulkList(users);
    localStorage.setItem('TasqUsernamesFilter', JSON.stringify(tasqsListModule.usernamesList));
  }

  @Debounce(1000)
  @Watch('filterValInput')
  updateFilterVal(data) {
    this.currentTasqLimit = 20;
    if (this.tasqListLevel === 'WellView') {
      this.currentTasqLimit = 10;
    }

    this.applyFiltering(true);

    this.filterVal = data;
  }

  destroyed() {
    localStorage.removeItem('tasqFilterValue');
  }

  paginationHandler({ target: { scrollTop, clientHeight, scrollHeight } }) {
    if (this.readyForScrolling) {
      // this.showInfiniteScrollLoading = true;
      if (Math.ceil(scrollTop + clientHeight + 150) >= scrollHeight && !this.registerScrollEvent) {
        this.registerScrollEvent = true;
        tasqsListModule.setInfiniteListScroll(scrollHeight);
        const list = this.listOptions.find((i) => i.val === this.activeList) as {text: string, val: string};
        // tasqsListModule.getTasqsByPagination(list);
        this.sleep(50).then(() => {
          // const infiniteList = document.getElementById('infinite-list')!;
          // infiniteList.scrollTo(0, scrollHeight);
          // this.showInfiniteScrollLoading = false;
          this.registerScrollEvent = false;
          // if(this.filteredTasqs.length >)
          // if (this.currentTasqLimit < 520) {
          this.currentTasqLimit += 20;
          // }
        });
      }
    }
  }

  async fetchTasq(tasq) {
    await tasqsListModule.setActiveTasqExplicit(tasq);
  }

  destroy() {
    // eslint-disable-next-line no-restricted-globals
    removeEventListener('online', this.onlineMode);
    // eslint-disable-next-line no-restricted-globals
    removeEventListener('offline', this.offlineMode);
  }

  async created() {
    // tasqsListModule.setIsLoadingTasqs(true);

    await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });

	  localStorage.removeItem('tasqFilterValue');

    this.registerScrollEvent = true;
    tasqsListModule.resetPagination();
    this.selectedTeams = [];
    this.cycleTimeGreaterThanFilter = null;
    this.cycleTimeLessThanFilter = null;
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
    // this.isOnlineApp = await isOnline();
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setActivePage('Tasq');
    // await accountModule.getReassignmentList({ useExisting: true });
    tasqsListModule.setUsernamesList(null);
    // console.log(workflowModule.user)
    this.selectedUsernames = [];
    this.selectedRoutes = [];

    // console.log(workflowModule.user)

    // console.log(workflowModule.user);

    // console.log(workflowModule.user.defaultUserFilter);
    // console.log(workflowModule.user.team);

    if (workflowModule.user.defaultUserFilter === 'Custom') {
      const defaultFilteringSettings: any = await userPreferenceDB.getItem('user-tasq-page-filtering-settings');

      const ObjectMapping = {
        typesOptions: 'jobTypeFilter',
        selectedUsernames: 'usernamesList',
        selectedTeams: 'activeFilterTeam',
        selectedRoutes: 'activeFilterRoute',
        selectedAreas: 'activeFilterArea',
        selectedWaitingOn: 'activeFilterWaitingOn',
        cycleTimeLessThanFilter: 'cycleTimeLessThanFilter',
        unitsGreaterThanFilter: 'unitsGreaterThanFilter',
        unitsLessThanFilter: 'unitsLessThanFilter',
        defermentPercentLessThanFilter: 'defermentPercentLessThanFilter',
        cycleTimeGreaterThanFilter: 'cycleTimeGreaterThanFilter',
        defermentPercentGreaterThanFilter: 'defermentPercentGreaterThanFilter',
      };

      this.$nextTick(() => {
        const { useLocalFilter } = tasqsListModule;
        // console.log(user);
        if (defaultFilteringSettings && Object.keys(defaultFilteringSettings) && Object.keys(defaultFilteringSettings).length) {
          Object.keys(defaultFilteringSettings).forEach((key) => {
            if (tasqsListModule[ObjectMapping[key]] && tasqsListModule[ObjectMapping[key]].length && useLocalFilter) {
              this[key] = tasqsListModule[ObjectMapping[key]];
            } else if (defaultFilteringSettings[key] && defaultFilteringSettings[key].length && !useLocalFilter) {
              console.log('not local');
              this[key] = defaultFilteringSettings[key];
            }
          });
        }
      });
    } else if (workflowModule.user.defaultUserFilter === 'Assigned to my team') {
      if (workflowModule.user.team) {
        console.log(workflowModule.user.team);
        this.selectedTeams = [workflowModule.user.team];
        tasqsListModule.setActiveTeamFilter(this.selectedTeams);
      }
    } else {
      // console.log(workflowModule.user.email);
      tasqsListModule.setUsernamesList(accountModule.user.email);
      // console.log(workflowModule.user);
      this.selectedUsernames = [{ value: workflowModule.user.email, text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}` }];
    }

    // if (workflowModule.user.defaultUserFilter === 'All') {
    //   // No filters applied
    //   // this.selectedAreas = workflowModule.user.areas;
    // } else if (workflowModule.user.defaultUserFilter === 'My Routes' && workflowModule.user.routes) {
    //   this.selectedRoutes = JSON.parse(workflowModule.user.routes);
    //   tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
    // } else if (workflowModule.user.defaultUserFilter === 'Myself') {
    //   tasqsListModule.setUsernamesList(accountModule.user.email);
    //   this.selectedUsernames = [(this.availableUsers.find((u) => u.value === accountModule.user.email))];
    // } else if (workflowModule.user.role === 'My Area' && workflowModule.user.areas) {
    //   this.selectedAreas = workflowModule.user.areas;
    //   tasqsListModule.setActiveAreaFilter(this.selectedAreas);
    // } else if ((workflowModule.user.role === 'Operators' || workflowModule.user.role === 'FieldOperator') && workflowModule.user.routes) {
    //   this.selectedRoutes = JSON.parse(workflowModule.user.routes);
    //   tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
    // } else if (!(workflowModule.user.role === 'Operators' || workflowModule.user.role === 'FieldOperator') && workflowModule.user.areas) {
    //   // console.log('My Areas');
    //   this.selectedAreas = workflowModule.user.areas;
    //   tasqsListModule.setActiveAreaFilter(this.selectedAreas);
    // }

    const level = workflowModule.user != null && workflowModule.user.role != null && (workflowModule.user.role == 'Operators' || workflowModule.user.role == 'FieldOperator' || workflowModule.user.defaultPage == 'TasqList-Pad') ? 'Pad' : 'Well';
    // console.log(level);
    if (level) {
      tasqsListModule.setTasqListLevel(level);
    }

    if (workflowModule.user.defaultPage && workflowModule.user.defaultPage.includes('well view')) {
      tasqsListModule.setTasqListLevel('WellView');
      // console.log(tasqsListModule.tasqListLevel);
    } else if (workflowModule.user.defaultPage && workflowModule.user.defaultPage === 'pad view') {
      tasqsListModule.setTasqListLevel('Pad');
    } else if (workflowModule.user.defaultPage && workflowModule.user.defaultPage === 'tasq view') {
      tasqsListModule.setTasqListLevel('Tasq');
    }

    const promiseList: any = [];
    promiseList.push(await this.changeTasqList());
    promiseList.push(workflowModule.listTeams({ useExisting: true }));
    promiseList.push(scheduleModule.listAreas({ useExisting: true }));
    promiseList.push(scheduleModule.getEnabledRoutes());
    // promiseList.push(tasqsListModule.getWaitList());

    Promise.all(promiseList).then(async () => {
      await this.setWaitingOnOptions();
      this.isLoadingDetails = false;
      console.log('resolved');
    }, (err) => {
      console.log('Error:');
      console.log(err);
    });

    this.readyForScrolling = true;

    if (this.isOnlineApp === false) {
      this.$eventBus.$emit(SHOW_ALERT, 'App is running offline mode!');
    }

    window.addEventListener('online', this.onlineMode);
    window.addEventListener('offline', this.offlineMode);

    this.registerScrollEvent = false;
  }

  async onlineMode() {
    this.$eventBus.$emit(SHOW_ALERT, 'App is running in online mode!');
    await this.applyFiltering();
  }

  async offlineMode() {
    this.$eventBus.$emit(SHOW_ALERT, 'App is running offline mode!');
    await this.applyFiltering();
  }

  async setWaitingOnOptions() {
    await metaDataModule.getDatalist();
    const requiredDataList = metaDataModule.requiredDatalist;
    const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
    // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
    if (formCategoriesItem) {
      const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
      if (formCategoriesPayload && formCategoriesPayload.length) {
        const waitingOnOptions = formCategoriesPayload[0].ListItems.filter((field) => field.Active).map((item) => item.Name);
        // waitingOnOptions =
        this.waitingOnOptions = ['None'].concat(waitingOnOptions.sort());
      }
    }
  }

  @Watch('isLoadingTasqs')
  @Debounce(1000)
  async updateTasqsValue(value) {
    // @ts-ignore
    const scrollDivId = localStorage.getItem('ActiveTasqScroll');
    // console.log(scrollDivId);
    if (value === false) {
      this.applyFiltering();
      if (tasqsListModule.kanbanSelectedTasq) {
        tasqsListModule.setKanbanSelectedTasq('');
      }
    }
    if (scrollDivId && scrollDivId.length) {
      this.sleep(2000).then(() => {
        const activeTasqDiv = document.getElementById(scrollDivId)!;
        if (activeTasqDiv) {
          activeTasqDiv.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
          localStorage.setItem('ActiveTasqScroll', '');
        }
      });
    }
  }

   @Watch('isLoadingPaginatedTasqs')
  @Debounce(1000)
  async updateTasqsPaginatedValue(value) {
    if (value === false) {
      this.applyFiltering(true);
    }
  }
}
